<template>
    <div class="app-container">
        <div style="width:800px;margin:0 auto">
            <el-steps :active="active" align-center>
                <el-step title="基础信息"></el-step>
                <el-step title="经营信息" v-if="userType!='1'"></el-step>
                <el-step title="结算信息"></el-step>
                <el-step title="收益配置"></el-step>
                <el-step title="返现配置"></el-step>
            </el-steps>
        </div>
        <BaseInfo @change="getBaseInfo" :provinceList="provinceList" v-show="active==0"></BaseInfo>
        <BusinessInfo @change="getBusinessInfo" v-if="userType!='1'" :legalName="legalName" v-show="active==1"></BusinessInfo>
        <SettleInfo @change="getSettleInfo" :companyName="companyName" :legalName="legalName" :provinceList="provinceList" v-show="active==2"></SettleInfo>
        <BenefitConfig @change="getBenefitConfig" :parentAgentNo="parentAgentNo" v-show="active==3"></BenefitConfig>
        <CashBackConfig @change="getCashBackConfig" :parentAgentNo="parentAgentNo" v-show="active==4"></CashBackConfig>
        <el-dialog
            title="入网成功"
            :visible.sync="dialogVisible"
            width="40%"
            :show-close="false"
            :close-on-click-modal="false">
            <p>{{addTips}}</p>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="handleClose">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import BaseInfo from '@/components/agentUpdateBase/baseInfo'
import BusinessInfo from '@/components/agentUpdateBase/businessInfo'
import SettleInfo from '@/components/agentUpdateBase/settleInfo'
import BenefitConfig from '@/components/agentUpdateBase/benefitConfig'
import CashBackConfig from '@/components/agentUpdateBase/cashBackConfig'
import { AgentApi } from '@/api'
import { mapState } from 'vuex'
export default {
    data() {
        return {
            active: 0,
            parentAgentNo: 0,
            legalName: '',
            params: {},
            userType: '',
            companyName:'',
            addTips:'',
            dialogVisible: false,
        }
    },
    components:{
        BaseInfo,
        BusinessInfo,
        SettleInfo,
        BenefitConfig,
        CashBackConfig
    },
    created(){
        this.userType = this.$route.query.userType
    },
    computed:{
        ...mapState('app', ['provinceCityArea']),
        provinceList(){
            return '' || this.provinceCityArea
        }
    },
    methods: {
        getBaseInfo(params,type){
            if(type=='next'){
                this.active = this.userType =='1'? 2:1
                this.params.baseInfo = params
                this.parentAgentNo = params.parentAgentNo ? params.parentAgentNo: '0'
                this.legalName = params.legalName
            } if(type=='prev'){
                 params.parentAgentNo = params.parentAgentNo == '0' ? '' :params.parentAgentNo
            }
            
        },
        getBusinessInfo(params,type){
            if(type=='next'){
                this.active ++
                this.params.businessInfo = params
                this.companyName = params.companyName
            } if(type=='prev'){
                this.active = 0
            }
        },
        getSettleInfo(params,type){
            if(type=='next'){
                this.active ++
                this.params.setterInfo = params
            } if(type=='prev'){
                this.active = this.userType =='1'? 0:1
            }
        },
        getBenefitConfig(params,type){
            console.log('benefit config:'+type);
            console.log(params);
            if(type=='next'){
                this.active ++
                this.params.policyInfoList = params;
                console.log(params.policyInfoList);
            } if(type=='prev'){
                this.active --
            } 
        },
        getCashBackConfig(params,type){
            if(type=='submit'){
                this.params.agentActivityInfoDtoList = params
                console.log(this.params)
                this.params.userType = this.userType
                AgentApi.saveAgent(this.params)
                    .then(res=>{
                        if(res.success){
                            this.addTips = res.data.tip
                            this.dialogVisible = true
                        } else{
                        }
                    })
            } if(type=='prev'){
                this.active --

            }
        },
        handleClose() {
            this.dialogVisible = false
            this.$router.push({
                name:'orgManage'
            })
        }
    },
}
</script>